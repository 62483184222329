<template>
  <div style="width: 100%">
    <div class="back-img">
      <div style="font-size: 50px; color: #fff">ORGANIZING COMMITTEE</div>
    </div>

    <div class="text-zone">
      <el-row>
        <el-col :md="8" :sm="12" v-for="colData in data">
          <div class="info-card" v-for="item in colData">
            <div class="title">{{item.title}}</div>
            <a>{{item.name}}</a>            
            <div>{{item.affi}}</div>
          </div>
        </el-col>
      </el-row>
    </div>

  </div>


</template>

<script>
export default {
  name: "Organizing",
  data() {
    return {
      data:
      [
        [
          {
            title:'Organizing Committee Members',
            name:'Zehcun Hu',
            affi:'Tsinghua University'
            
          },
          {
            title:'',
            name:'Tao Yu',
            affi:'South China University of Technology'
            
          },
          {
            title:'',
            name:'Juan Yu',
            affi:'Chongqing University'
            
          },
          {
            title:'',
            name:'Hui Liu',
            affi:'Guangxi University'
           
          },
          {
            title:'',
            name:'Zhenguo Shao',
            affi:'Fuzhou University'
            
          },
          {
            title:'',
            name:'Lei Xi',
            affi:'Three Gorges University'
            
          },
          {
            title:'Local Organizing Committee Members',
            name:'Qi Wang',
            affi:'Southeast University'
            
          },
          {
            title:'',
            name:'Yujian Ye',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Lucheng Hong',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Zhou Li',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Qinran Hu',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Jiaxing Lei',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Zhi Wu',
            affi:'Southeast University'
          },
        ],
        [
          {
            title:'',
            name:'Tao Jiang',
            affi:'Northeast Electric Power University'
          },
          {
            title:'',
            name:'Xinyu Chen',
            affi:'Huazhong University of Science and Technology'
          },
          {
            title:'',
            name:'Zongxiang Lu',
            affi:'Tsinghua University'
          },
          {
            title:'',
            name:'Mingyao Ma',
            affi:'Hefei University of Technology',
          },
          {
            title:'',
            name:'Yanli Liu',
            affi:'Tianjin University'
          },
          {
            title:'',
            name:'Gengfeng Li',
            affi:'Xi’an Jiaotong University'
          },
          {
            title:'',
            name:'Yifei Wang',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Xi Wu',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Yueming Wang',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Huan Long',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Tao Chen',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Haiwei Cai',
            affi:'Southeast University'
          },
        ],
        [
          {
            title:'',
            name:'Lei Huang',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Yin Xu',
            affi:'Beijing Jiaotong University'
          },
          {
            title:'',
            name:'Chuan He',
            affi:'Sichuan University'
          },
          {
            title:'',
            name:'Gang Lv',
            affi:'Beijing Jiaotong University'
          },
          {
            title:'',
            name:'Xiaofeng Ding',
            affi:'BeiHang University'
          },
          {
            title:'',
            name:'Zhongze Wu',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Suyang Zhou',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Xiaohui Qu',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Meng Song',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Fei Zhang',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Xiangjun Quan\t',
            affi:'Southeast University'
          },
          {
            title:'',
            name:'Hao Ming',
            affi:'Southeast University'
          },
        ],
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/committees";

</style>
